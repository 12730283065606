<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <RequireLogin>
            <v-row justify="center" class="pt-5">
                <h1 class="display-1 font-weight-light">Account</h1>
            </v-row>
            <v-row justify="center" class="pb-5">
                <p class="caption font-weight-light">{{email}}</p>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="title text-center pa-0 pb-3">Subscriptions</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3" v-if="!isReadySubscriptionList">
                            <v-col cols="12" class="px-5">
                                <p class="font-weight-light body-1 text-center">Loading data...</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-3" v-if="isReadySubscriptionList && subscriptionList.length === 0">
                            <v-col cols="12" class="px-5">
                                <p class="font-weight-light body-1 text-center">You don't have any subscriptions.</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-3" v-for="subscription in subscriptionList" v-bind:key="subscription.id">
                            <v-col cols="12" class="px-5">
                                <p class="font-weight-light body-1 text-center">{{subscription.id}}</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="title text-center pa-0 pb-3">Orders</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3" v-if="!isReadyOrderList">
                            <v-col cols="12" class="px-5">
                                <p class="font-weight-light body-1 text-center">Loading data...</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-3" v-if="isReadyOrderList && orderList.length === 0">
                            <v-col cols="12" class="px-5">
                                <p class="font-weight-light body-1 text-center">You don't have any orders.</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="pb-3" v-for="order in orderList" v-bind:key="order.id">
                            <v-col cols="12" class="px-5">
                                <p class="font-weight-light body-1 text-center">{{order.id}}</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </RequireLogin>
        </v-col>
    </SectionLayout>
</template>

<style scoped>
p {
    color: #555 !important;
}
p a  {
    color: #00f !important;
}
p a:visited  {
    color: #00f !important;
}
p a:hover  {
    color: #00f !important;
    text-decoration: underline;
}
</style>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import SectionLayout from '@/components/SectionLayout.vue';
import RequireLogin from '@/components/RequireLogin.vue';
import { Client } from '@/client';

const client = new Client();

export default {
    components: {
        RequireLogin,
        SectionLayout,
    },
    data() {
        return {
            name: null,
            alias: null,
            email: null,
            subscriptionList: [],
            orderList: [],
            isReadySubscriptionList: false,
            isReadyOrderList: false,
        };
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
            // name: (state) => state.user.name,
            // alias: (state) => state.user.alias,
        }),
    },
    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },
    methods: {
        async init() {
            console.log(`Account.vue: init user ${JSON.stringify(this.user)}`);
            this.name = this.user.name;
            this.alias = this.user.alias;
            this.email = this.user.email;
            this.loadCustomerSubscriptionList();
            this.loadCustomerOrderList();
        },
        async loadCustomerSubscriptionList() {
            try {
                this.$store.commit('loading', { loadCustomerSubscriptionList: true });
                const response = await client.account.getSubscriptionList();
                if (response && response.data && response.data.length > 0) {
                    this.subscriptionList = response.data;
                    // look for specific subscription types to show relevant settings
                    // const vpnList = response.data.filter((item) => item.info.service === 'vpn');
                    // this.isVpnEnabled = vpnList.length > 0;
                }
                console.log(`loadCustomerSubscriptionList response: ${JSON.stringify(response)}`);
            } catch (err) {
                console.log('loadCustomerSubscriptionList failed', err);
            } finally {
                this.$store.commit('loading', { loadCustomerSubscriptionList: false });
                this.isReadySubscriptionList = true;
            }
        },
        async loadCustomerOrderList() {
            try {
                this.$store.commit('loading', { loadCustomerOrderList: true });
                const response = await client.account.getOrderList();
                console.log(`loadCustomerOrderList response: ${JSON.stringify(response)}`);
            } catch (err) {
                console.log('loadCustomerOrderList failed', err);
            } finally {
                this.$store.commit('loading', { loadCustomerOrderList: false });
                this.isReadyOrderList = true;
            }
        },
    },
    mounted() {
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
